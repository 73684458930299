import { Platform } from '@angular/cdk/platform';
import { HostListener, Injectable } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { timer, firstValueFrom } from 'rxjs';
import { take } from 'rxjs/operators';
import { PwaPromptComponent } from '../components/pwa-prompt/pwa-prompt.component';
import { AuthService } from 'src/app/auth/services/auth.service';
import { User } from '@angular/fire/auth';

@Injectable({
  providedIn: 'root'
})
export class PwaService {
  private promptEvent: any;
  private showPrompt = true;

  constructor(
    private bottomSheet: MatBottomSheet,
    private platform: Platform
  ) {}

  public async initPwaPrompt(authService: AuthService): Promise<void> {
    console.log('Initializing PWA prompt');

    // ✅ Get the current user state from AuthService
    const firebaseUser: User | null = await firstValueFrom(authService.getFirebaseUser());

    if (!firebaseUser) {
      console.log('User is not authenticated, skipping PWA prompt.');
      return;
    }

    if (this.platform.ANDROID) {
      window.addEventListener('beforeinstallprompt', (event: any) => {
        if (!this.showPrompt) return;
        this.showPrompt = false;
        event.preventDefault();
        this.promptEvent = event;
        this.openPromptComponent('android');
      });
    } else if (this.platform.IOS) {
      if (!this.showPrompt) return;
      this.showPrompt = false;
      const isInStandaloneMode = ('standalone' in window.navigator) && ((window.navigator as any)['standalone']);
      if (!isInStandaloneMode) {
        this.openPromptComponent('ios');
      }
    }
  }

  @HostListener('window:beforeinstallprompt', ['$event'])
  onbeforeinstallprompt(e: Event) {
    if (!this.showPrompt) return;
    this.showPrompt = false;
    e.preventDefault();
    this.promptEvent = e;
    if (this.platform.ANDROID) {
      this.openPromptComponent('android');
    }
  }

  private openPromptComponent(mobileType: 'ios' | 'android') {
    timer(3000)
      .pipe(take(1))
      .subscribe(() =>
        this.bottomSheet.open(PwaPromptComponent, {
          data: { mobileType, promptEvent: this.promptEvent }
        })
      );
  }
}
