import { Injectable } from '@angular/core';
import { Resolve, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { firstValueFrom, combineLatest } from 'rxjs';
import { filter } from 'rxjs/operators';
import { OrganizationConfig } from '../models/organization/config';
import { OrganizationConfigService } from '../services/organization-config.service';

@Injectable({
  providedIn: 'root'
})
export class OrganizationConfigResolver implements Resolve<OrganizationConfig> {

  constructor(private configService: OrganizationConfigService) {}

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<OrganizationConfig> {
    try {
      console.log('OrganizationConfigResolver: Waiting for config to load...');

      const config = await firstValueFrom(
        combineLatest([
          this.configService.configAsObservable(),
          this.configService.configLoaded$
        ]).pipe(
          filter(([config, loaded]) => loaded) // ✅ Only proceed when config is fully loaded
        )
      );

      //console.log('✅ Organization config resolved:', config[0]);
      return config[0]; // Return the first element, which is the actual config
    } catch (error) {
      console.error('🚨 OrganizationConfigResolver: Error resolving organization config', error);
      throw error; // 🔴 Ensures Angular handles navigation failures properly
    }
  }
}
