import { Injectable } from '@angular/core';
import { Resolve, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { Organization } from '../models/organization/organization';
import { NO_ORG_ID, OrganizationService } from '../services/organization.service';

@Injectable({
  providedIn: 'root'
})
export class OrganizationResolver implements Resolve<Organization> {

  constructor(private organizationService: OrganizationService) {
    console.log('📌 OrganizationResolver initialized');
  }

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<Organization> {
    try {
      const org = await firstValueFrom(
        this.organizationService.orgAsObservable()
      );

      if (!org.id || org.id === NO_ORG_ID) {
        console.warn('⚠️ OrganizationResolver: No valid organization found.');
        return new Organization(); // Return an empty Organization instead of blocking the route
      }

      ///console.log('✅ OrganizationResolver: Organization resolved:', org);
      return org;

    } catch (error) {
      console.error('🚨 OrganizationResolver: Error resolving organization', error);
      return new Organization(); // Provide fallback organization to prevent navigation errors
    }
  }
}
