import { isDevMode, NgModule, inject, provideAppInitializer } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule, SwRegistrationOptions } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { CoreModule } from './core/core.module';
import { AuthModule } from './auth/auth.module';
import { LoginModule } from './login/login.module';
// import { NgxsFirestoreModule } from '@ngxs-labs/firestore-plugin';
import { NgxsModule } from '@ngxs/store';
import { PwaService } from './core/services/pwa.service';
import { AuthService } from './auth/services/auth.service';
import { VersionService } from './core/services/version.service';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { UserOrganizationsState } from './profile/states/user-organizations/user-organizations.state';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { MatFormFieldModule } from '@angular/material/form-field';
import { provideFirebaseApp, initializeApp } from '@angular/fire/app';
import { provideMessaging, getMessaging } from '@angular/fire/messaging';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { getAuth, provideAuth, connectAuthEmulator } from '@angular/fire/auth';
import { NgxsReduxDevtoolsPluginModule } from "@ngxs/devtools-plugin";
import { MatIconModule } from '@angular/material/icon';
import { getStorage, provideStorage } from '@angular/fire/storage';
import { AngularFireModule } from '@angular/fire/compat';
import { NgxSpinnerModule } from 'ngx-spinner';
import { SharedModule } from './shared/shared.module';
import { LoadingService } from './shared/services/loading.service';

export function fetchVersion(buildDetailsService: VersionService) {
  return () => buildDetailsService.getVersion().then((version) => version);
}

@NgModule({
  declarations: [
    AppComponent,
  ],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MatSnackBarModule,
    MatIconModule,
    MatFormFieldModule,
    AngularFireModule.initializeApp(environment.firebase),
    NgxsReduxDevtoolsPluginModule.forRoot(),
    NgxsModule.forRoot([UserOrganizationsState], { developmentMode: !environment.production }),
    CoreModule,
    AuthModule,
    SharedModule,
    LoginModule,
    AppRoutingModule,
    //NgxSpinnerModule.forRoot({ type: 'ball-scale-multiple' }),
    ServiceWorkerModule.register(environment.production ? 'main-sw-dev.js' : 'main-sw.js', {
      enabled: environment.production,
      registrationStrategy: 'registerImmediately'
    }),
  ],
  providers: [
    AngularFirestore,
    LoadingService,
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideStorage(() => getStorage()),
    provideFirestore(() => getFirestore()),
    provideAuth(() => getAuth()),
    provideMessaging(() => getMessaging()), // ✅ Added Firebase Messaging
    provideAppInitializer(() => {
      const pwaService = inject(PwaService);
      const authService = inject(AuthService);
      return pwaService.initPwaPrompt(authService); // Ensure this returns void, Promise, or Observable
    }),
    
    provideAppInitializer(() => {
      fetchVersion(inject(VersionService));
    }),
    provideHttpClient(withInterceptorsFromDi()),
  ]
})
export class AppModule { }