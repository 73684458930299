import { Injectable } from '@angular/core';
import { Resolve, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { CountryCodeService } from '../services/country-code.service';

@Injectable({
  providedIn: 'root'
})
export class CountryCodeResolver implements Resolve<boolean> {
  
  constructor(private countryCodeService: CountryCodeService) {}

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    try {
      const resolved = await firstValueFrom(this.countryCodeService.orgResolved);
      console.log('✅ Country code resolved:', resolved);
      return resolved;
    } catch (error) {
      console.error('🚨 CountryCodeResolver: Error resolving country code', error);
      return false; // Fallback to `false` in case of failure
    }
  }
}
